.box-deal {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
}

.column-deal .hide {
  display: none !important;
  opacity: 0 !important;
}

.pipeline-header {
  background-color: #f9fbfc;
}

.pipelines-board {
  height: calc(100vh - 176px) !important;
  box-sizing: border-box;
}

.pipelines-board-col {
  height: calc(100vh - 268px) !important;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #fff;
}
.pipelines-board-col .pipelines-board-col .column-deal h3 {
  color: #858588 !important;
  font-size: 0.984375rem;
}

.pipelines-board-col .activities-btn {
  margin-left: auto;
}
.parent-board-header {
  z-index: 10;
  border-radius: var(--borderRadius);
}
.board-header {
  overflow: hidden;
}
.board-header:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  display: block;
  top: 0;
  background-color: var(--secondaryColor) !important;
}
.board-header-won:after {
  background-color: var(--wonDarkColor) !important;
}
.board-header-lost:after {
  background-color: var(--text-red) !important;
}

.column-deal .card {
  box-shadow: none !important;
  margin-bottom: 8px !important;
}
.column-deal .card .hover-actions.pb-2 {
  padding-bottom: 0px !important;
}
.column-deal .card .card-body .deal-card {
  min-height: auto !important;
  padding: 0px;
  margin: 0px;
}
.column-deal .card .card-body .deal-card .col-12 {
  padding: unset;
}
.column-deal .card .card-body .deal-card p {
  margin: 0px !important;
}
.column-deal .card-body.p-3.pb-0 {
  padding: 4px 12px !important;
}
.column-deal .card-body .row {
  margin-right: unset !important;
  margin-left: unset !important;
}
.column-deal .card:hover {
  border-color: var(--secondaryColor) !important;
}
.board-header:last-child {
  border-right: 1px solid #e5e5e5 !important;
}

.board-footer {
  padding-left: calc(var(--menu-width) + var(--second-column-width));
  width: inherit;
  max-width: inherit;
  z-index: 50;
  transition: all 0.3s linear;
}

.board-footer .board-footer-item {
  width: 100%;
  height: 63px;
  margin-right: 1rem;
}

.board-footer .board-footer-item:last-child {
  margin: 0;
}

.parent-column .col:hover a.add-deal {
  display: block !important;
}

.opacity-0 {
  opacity: 0;
  transform: translateY(100px);
}

.opacity-1 {
  opacity: 1;
  transform: translateY(0);
}

.opacity-11 {
  opacity: 1 !important;
  pointer-events: visible !important;
}

.board-footer .board-footer-item.won {
  color: #555;
  background-color: #f7fdfb;
  border: 2px dashed var(--wonColor) !important;
}

.board-footer .board-footer-item.lost {
  color: #555;
  background-color: #fff2f3;
  border: 2px dashed var(--lostColor) !important;
}

.board-footer .board-footer-item.delete {
  color: #555;
  background-color: #f5f8fa;
  border: 2px dashed var(--deleteColor) !important;
}

.deal-types {
  transition: all 0.2s ease-in-out;
}

.deal-types.lost,
.deal-types.won,
.deal-types.hot,
.deal-types.delete,
.deal-types.cold {
  transform: scale(1.03);
}

.deal-types.lost {
  background: var(--lostColor) !important;
  & * {
    color: white !important;
  }
}

.deal-types.won {
  background: var(--wonColor) !important;
  & * {
    color: black !important;
  }
}
.btn-danger {
  background-color: var(--lostDarkColor) !important;
  border-color: var(--lostDarkColor) !important;
}

.btn-danger:hover {
  background-color: var(--lostDarkColorHover) !important;
  border-color: var(--lostDarkColorHover) !important;
}

.btn-success {
  background-color: var(--wonDarkColor) !important;
  border-color: var(--wonDarkColor) !important;
}

.btn-success:not(.cursor-default):hover {
  background-color: var(--wonDarkColorHover) !important;
  border-color: var(--wonDarkColorHover) !important;
  color: #fff !important;
}

.badge-success {
  background-color: var(--wonDarkColor) !important;
}

.deal-col {
  min-width: 260px;
  max-width: 260px !important;
}
