.border-1 {
  border-style: solid;
  border-width: 1px !important;
}
.border-2 {
  border-style: solid;
  border-width: 2px !important;
}

.widget-border-2 {
  border: 0.0625rem solid #e7eaf3 !important;
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.item-filter:hover {
  background: var(--table-hover);
}

.p-item-filter {
  padding: 10px !important;
}

.dropdown-input:after {
  content: none !important;
}

.menu-drop-style {
  border-radius: 0 !important;
  max-height: 350px;
  overflow-y: auto;
}
