.main-owner div {
  border: 2px solid;
}

.more-owners {
  display: flex;
}

.main-owner:hover,
.more-owners div:hover,
.section-owners-header button:hover {
  transform: scale(1.1);
  z-index: 10;
}

/* hack :\ to not scale img more than 1*/
.avatar-xs:has(img):hover {
  transform: scale(0.9);
}
.section-owners-header button {
  color: var(--secondaryColor) !important;
}
.section-owners-header .dropdown .btn span {
  font-size: 0.684375rem !important;
}
