.activities-btn .btn-primary.dropdown-toggle:active {
  background-color: #f1f1f1 !important;
}

.activities-btn .btn-primary.dropdown-toggle:hover {
  background-color: #f1f1f1 !important;
}

.activities-without-activities {
  color: #e7b400 !important;
}

.activities-overdue {
  color: #f44336 !important;
}

.activities-planned {
  color: #71869d !important;
}

.activities-today {
  color: #27ae60 !important;
}

.cards-btn-deals .rounded-circle {
  border-radius: 50%;
  border: none;
  width: 45px;
  height: 45px;
  margin-bottom: 5%;
}

.activities-btn .rounded-circle {
  background: none;
}

.pipelines-board-col .activities-btn .modal-history-activities {
  top: -12px !important;
}
.modal-history-activities .title {
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2% 3.5%;
}

.modal-history-activities .title h4 {
  font-size: 0.984375rem;
  margin: 0;
}

.modal-history-activities .title span {
  border-radius: 10rem;
  color: #fff;
  background-color: #71869d;
  padding: 0.25rem 0.625rem !important;
  font-weight: 600;
}

.modal-history-activities .schedule {
  padding: 1.5% 3.5%;
}

.modal-history-activities .activities {
  padding: 0% 3%;
  max-height: 500px;
  overflow: overlay;
}

.modal-history-activities .schedule {
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}

@media (max-width: 575.98px) {
  .activities-btn .dropdown-menu.show {
    width: 250px;
  }
}

.schedule button {
  text-align: left;
  width: 50%;
  font-weight: 600;
  padding: 0.4375rem 0.65625rem;
  border: none;
  background: transparent;
}

.schedule button span {
  margin-right: 1%;
}

.modal-create-activity .modal-content {
  padding: 2%;
  width: 110%;
}

.card-body .info {
  width: 85%;
}

.activity-icon .step-icon {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.activities .card {
  border: none;
  box-shadow: none;
}

.activities hr {
  margin: 0.1%;
  border-top: 0.0625rem solid #e7eaf3;
}

.modal-create-activity .modal-header {
  padding: 0% !important;
}

.menu-guest {
  width: auto !important;
  position: fixed !important;
}
