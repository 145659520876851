body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--body-bg-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.uai.userway_dark {
  min-width: unset !important;
  min-height: unset !important;
  width: 40px !important;
  height: 40px !important;
  padding: 3px !important;
}

.uai.userway_dark .uiiw {
  display: block;
  top: -8.5px;
  position: relative;
  right: -0.1px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer-important {
  cursor: pointer !important;
  pointer-events: auto !important;
}

.cursor-text {
  cursor: text;
}

.cursor-grab {
  cursor: grab;
}

.cursor-help {
  cursor: help;
}

.rank-font-size {
  font-size: 1.5rem;
}

.import-icon {
  font-size: 3.5rem !important;
}

.width-2-rem {
  width: 2rem;
}

.width-6-rem {
  width: 6rem;
}

.drp-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 12px;
}

.slide {
  height: 315px;
  overflow-y: auto;
  /* padding-right: 100px; */
}

.slide ul {
  padding-left: 0;
  list-style: none;
  text-align: left !important;
}

.slide ul li {
  position: relative;
  display: block;
  padding-left: 1.75rem;
  margin: 0.5rem;
  color: #000 !important;
}

.slide ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-top: 0.125rem;
}

.slide ol {
  padding-left: 0;
  list-style: none;
  text-align: left !important;
  counter-reset: item;
}

.slide ol li {
  counter-increment: item;
  position: relative;
  display: block;
  padding-left: 1.75rem;
  margin: 0.5rem;
  color: #000 !important;
}

.slide ol li::before {
  position: absolute;
  top: 0;
  left: 4px;
  width: 1rem;
  height: 1rem;
  content: counter(item) '.';
  margin-top: 0.125rem;
}

.quiz ul {
  padding-left: 0;
  list-style: none;
  text-align: left !important;
}

.quiz ul li {
  position: relative;
  display: block;
  padding-left: 1.75rem;
  margin: 0.5rem;
  color: #000 !important;
}

.quiz ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: '';
  margin-top: 0.125rem;
}

.quiz ol {
  padding-left: 0;
  list-style: none;
  text-align: left !important;
  counter-reset: item;
}

.quiz ol li {
  counter-increment: item;
  position: relative;
  display: block;
  padding-left: 1.75rem;
  margin: 0.5rem;
  color: #000 !important;
}

.quiz ol li::before {
  position: absolute;
  top: 0;
  left: 4px;
  width: 1rem;
  height: 1rem;
  content: counter(item) '.';
  margin-top: 0.125rem;
}

.new-navbar-dropdown-account-wrapper {
  background-color: transparent;
  color: var(--black);
  margin-left: 10rem;
  transition: 0.3s;
}

.h-spinner {
  min-height: 220px;
}

.edit-avatar-container {
  width: 100px;
  height: 100px;
  border: 3px #3c7ff7 solid;
  border-radius: 50%;
  position: relative;
}

.edit-avatar-container:hover {
  opacity: 0.8;
  cursor: pointer;
}

.new-edit-avatar-container {
  cursor: pointer;
  position: relative;
}

.new-edit-avatar-container:hover .hover-img {
  transform: scale(1.1);
}

.new-edit-avatar-container .hover-img {
  display: block;
  transform: scale(0);
  transition: 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  cursor: pointer;
  background-color: rgba(36, 46, 67, 0.9);
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.hover-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.edit-avatar-icon {
  border-radius: 50%;
  background-color: #3c7ff7;
  color: white;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
}

.edit-avatar-icon .material-symbols-rounded.fs-5 {
  font-size: 1rem !important;
}

.max-wh {
  width: 100% !important;
  height: 100% !important;
}

.max-h-300 {
  max-height: 300px;
}

.max-h-120 {
  max-height: 120px;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-670 {
  max-width: 670px !important;
}

.all-option.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-left: 0;
  position: absolute;
  right: 4px;
  padding-right: 8px;
}

.btn-h-sm {
  height: 36px;
}
@media screen and (min-width: 1199px) {
  .sidebar-collapse {
    display: none !important;
  }
}

@media screen and (max-width: 1199px) {
  .sidebar-mobile {
    position: absolute !important;
    width: 100%;
    z-index: 10;
  }

  .avatar-mobile {
    position: absolute;
    right: 0;
  }
}

.animation-duration-3 {
  animation-duration: 300ms;
}

.min-width-22 {
  min-width: 22rem !important;
}

.min-width-18 {
  min-width: 18rem !important;
}

.width-full {
  width: 100% !important;
}

.height-full {
  height: 100% !important;
}

.selected-item {
  background-color: rgba(231, 234, 243, 0.7);
}

.primary-selected-item,
.portal-module-item:hover,
.portal-menu-item:hover .portal-menu-item-icon {
  background: hsl(var(--primaryColorHsl), 100%, 97%) !important;
  color: var(--secondaryColor) !important;
  border: 1px solid hsl(var(--primaryColorHsl), 100%, 97%);
}

.portal-menu-item {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.portal-menu-item:hover .portal-menu-item-icon {
  transform: rotate(0) skew(0, 0) scale(1.05);
}

.portal-menu-item-icon {
  border: 1px solid #fff;
}
.primary-selected-item {
  color: var(--secondaryColor) !important;
}

.portal-menu-item:hover .portal-menu-item-name {
  color: var(--primaryColor) !important;
  font-weight: 500 !important;
}
.portal-module-item {
  font-weight: 500 !important;
}

.primary-selected-item.bordered {
  border-color: hsl(var(--primaryColorHsl), 80%, 90%) !important;
}
.item:hover {
  cursor: pointer;
  background: rgba(231, 234, 243, 0.7);
}

.tag-item {
  background: hsl(var(--primaryColorHsl), 100%, 97%);
  color: var(--secondaryColor);
  font-size: 1rem;
  border-radius: 5px;
  height: 30px;
  padding: 0 0.4rem 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px solid hsl(var(--primaryColorHsl), 100%, 97%);
  transition: all 0.2s ease-in;
}

.tag-item.hovering:hover {
  border-color: var(--secondaryColor) !important;
}

.bg-primary-soft {
  background: hsl(var(--primaryColorHsl), 100%, 97%) !important;
}
.bg-primary-soft-hover:hover {
  background: hsl(var(--primaryColorHsl), 100%, 97%) !important;
}
.bg-primary-softer-hover:hover {
  background-color: hsl(var(--primaryColorHsl), 100%, 98%) !important;
}

.bg-primary-soft-hover-dark:hover {
  background: var(--secondaryColor) !important;
  color: white !important;
}
.bg-primary-soft-hover:hover span.tab-number {
  background: var(--secondaryColor) !important;
  color: white !important;
}

.bg-primary-soft-hover:hover label.tab-name {
  color: var(--secondaryColor) !important;
}
.bg-primary-dark {
  background: hsl(var(--primaryColorHsl), 50%, 50%);
}
.bg-primary-light {
  background: hsl(var(--primaryColorHsl), 65%, 65%);
}

.bg-primary-lighter {
  background: hsl(var(--primaryColorHsl), 100%, 90%) !important;
}

.dropdown-item.bg-primary:hover,
.dropdown-item.bg-primary:hover * {
  background: var(--secondaryColor) !important;
  filter: brightness(90%);
}

.rbt-token {
  background-color: hsl(var(--primaryColorHsl), 100%, 97%) !important;
  color: var(--secondaryColor) !important;
  font-size: 13px;
  border-radius: 5px;
}

.tag-item > .button,
.rbt-token > button.btn-close {
  background-color: transparent;
  color: var(--secondaryColor) !important;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbt-token > button.btn-close {
  margin-left: 0;
}

.has-error {
  border-color: tomato !important;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.nav-item {
  cursor: pointer;
}

.height-container-spinner {
  height: 200px;
  margin: auto;
}

.empty-leaderboard {
  margin: 10rem;
}

.help-position {
  position: fixed;
  bottom: 15px;
  background: rgb(45, 104, 255);
  right: 90px;
  width: 60px;
  height: 60px;
}

.alert {
  position: relative !important;
  top: 10px !important;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 101 !important;
}

.alert.alert-position:empty {
  width: 0;
}
.alert-position {
  position: fixed !important;
  z-index: 1052 !important;
}

.p-2 {
  padding: 2rem;
}

.hide-element {
  display: none;
}

.user-deactivated {
  opacity: 0.5;
}

.search-card {
  z-index: 10;
  width: 100%;
}

.search-input {
  box-sizing: border-box;
  background: #f9fafc;
}

.checkbox-row {
  width: 60px;
}

.user-width {
  width: 380px;
}

.role-width {
  width: 195px;
}

.status-width {
  width: 153px;
}

.card-section {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.avatar-icon-font-size-xs {
  font-size: 1.2rem !important;
}

.avatar-icon-font-size-xs svg {
  width: 19px;
}

.avatar-icon-font-size-xl svg,
.avatar-sm.max-wh svg {
  width: 48px;
  height: 48px;
}

.avatar-icon-font-size-sm {
  font-size: 1.5rem !important;
}

.confirmation-icon {
  font-size: 5rem !important;
}

.text-success {
  color: var(--wonDarkColor);
}

.text-success-excel {
  color: var(--excel-success) !important;
}
.bg-success-excel {
  background-color: var(--excel-success) !important;
}
.text-primary-excel {
  color: var(--excel-primary) !important;
}
.bg-primary-excel {
  background-color: var(--excel-primary) !important;
}
.text-yellow-excel {
  color: var(--excel-yellow) !important;
}
.bg-yellow-excel {
  background-color: var(--excel-yellow) !important;
}
.text-gray-excel {
  color: var(--excel-gray);
}
.bg-gray-excel {
  background-color: var(--excel-gray) !important;
}

.text-error {
  color: #f44336;
}

.section-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.btn-primary-dark {
  background: #111b51 !important;
}

.label-title {
  width: 10%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.section-subtitle {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.lesson-settings {
  max-width: 390px;
  width: 100%;
  border-left: 1px solid #e7eaf3;
}

.bw-large {
  width: 166px;
}

.slide-notification-wrapper {
  min-height: 245px;
}

.slide-notification {
  color: #677788;
  line-height: 25px;
  margin: 1em;
  font-size: 18px;
  font-weight: 600;
}

.slide-button-wrapper {
  width: 520px;
}

.pdf-section {
  min-height: 160px;
}

.pdf-avatar {
  width: 36px;
  height: 36px;
  background: #d6dbeb;
  color: #092ace;
  font-weight: bold;
}

.pdf-card {
  display: grid;
  grid-template-columns: 36px 1fr;
  column-gap: 1em;
}

.pdf-card-title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.pdf-card-description {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #97a4af;
}

.text-normal-bold {
  margin: 0;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
}

.margin-right-sm-2 {
  margin-right: 5px;
}

.tr-hover h5 {
  margin-bottom: 0;
}

.tr-hover:hover {
  background: rgba(231, 234, 243, 0.7);
}

.tr-hover:hover .hover-actions .action-items {
  opacity: 1 !important;
}
.w-th-45 {
  width: 45px;
}

.box-icons {
  max-height: 200px;
  overflow: auto;
  z-index: 10000;
  border: 1px solid rgba(231, 234, 243, 0.7);
  box-sizing: border-box;
}

.button-delete-icon {
  font-size: 24px;
  color: #677788;
}

.dropdown-icons {
  max-height: 200px;
  overflow: auto;
  z-index: 10000;
}

.option-wrapper {
  height: 40px;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-gray {
  color: #677788;
}

.alert-icon-size {
  font-size: 4rem !important;
}

@media (min-width: 576px) {
  .modal-small {
    max-width: 300px !important;
  }
}

.end-0 {
  right: 0 !important;
}

.z-index-alert {
  z-index: 10000;
}

.close-pdf {
  position: absolute;
  right: 2px;
  top: 4px;
}

.title-overflow {
  max-width: 500px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-title {
  font-weight: 600;
  font-size: 0.9rem;
}

.tag-size {
  font-size: 12px;
}

.overflow-table {
  overflow: auto;
}

.fw-bold {
  font-weight: 600 !important;
}

.bullet-color {
  width: 10px;
  height: 10px;
}

.drop-menu-card {
  max-height: 300px;
}
.rpt-history-dd-width {
  min-width: 250px;
}
.user-avatar-select {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.remove-additional-field {
  width: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remove-additional-field-icon {
  cursor: pointer;
  opacity: 0;
}

.input-group:hover .remove-additional-field-icon {
  opacity: 1;
}

.org-address {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
  row-gap: 1em;
}

.close-tag {
  font-size: 12px;
  margin-left: 0.5em;
}

.badge-list-icon {
  width: 30px;
}

.badge-card-icon {
  width: 50px;
}

.badge-create-icon {
  width: 50px;
}

.uppercase {
  text-transform: uppercase;
}

.preview {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 104px;
  height: 104px;
  padding-top: 20px;
  border-radius: 5px;
  background-color: #f6f8fa;
  border: 1px solid #d6dbeb;
}

.preview-text {
  color: #677788;
  background-color: #d6dbeb;
  width: 100%;
  text-align: center;
  font-size: 10px;
}

.mw-fix-100 {
  max-width: 100px;
}

.mw-fix-125 {
  max-width: 125px;
}

.mw-fix-150 {
  max-width: 150px;
}

.mw-fix-200 {
  max-width: 200px;
}

.ui-spinner-input {
  width: 50px !important;
  height: 50px !important;
}

.alert-fixed {
  top: 70px;
  right: 0px;
  z-index: 9999;
}

.center-item {
  display: flex;
  justify-content: center;
}

.height-300 {
  height: 300px !important;
}

.icon-book-lesson {
  font-size: 6em !important;
}

.text-opacity-25 {
  opacity: 25% !important;
}

.modal-70w {
  max-width: 70vw !important;
  margin-left: 16% !important;
}
.customFormGroup {
  width: 23%;
}

.deal-card {
  min-height: 82px !important;
}

.dropdown-hide-arrow:not(.dropdown-toggle-empty)::after {
  content: none;
}

.more-actions-font {
  font-size: 0.8125rem !important;
}

.size-progress-circle {
  width: 40px;
}

/*
  deal detail progress bar
*/
.step-bar {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  zoom: 1;
}

@media (min-width: 768px) {
  .step-bar {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    box-orient: horizontal;
    box-direction: normal;
    -moz-flex-direction: row;
    flex-direction: row;
  }
}

.step-bar > li {
  width: 100%;
  position: relative;
  background-color: #eee;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .step-bar > li {
    padding: 10px;
    margin-bottom: 5px;
  }
}

.step-bar > li > span {
  transition: none !important;
  text-decoration: none;
  display: block;
  color: white;
}

.step-bar > li:before,
.step-bar > li:after {
  position: absolute;
  display: block;
  content: '';
  background-color: #eee;
  border-left: 4px solid #fff;
  top: 0;
}

.step-bar > li:after {
  transform: skew(-30deg);
}

.step-bar > li:before {
  transform: skew(30deg);
}

.step-bar > li:first-child:before,
.step-bar > li:first-child:after {
  background: white;
  content: none;
}

.step-bar > li.active {
  background-color: #337ab7;
}

.step-bar > li.active:before,
.step-bar > li.active:after {
  background-color: #337ab7;
}

.step-bar > li.active:hover {
  background-color: #285f8f;
}

.step-bar > li.active:hover:before,
.step-bar > li.active:hover:after {
  background-color: #285f8f;
}

.step-bar > li.complete {
  background-color: #5cb85c;
}

.step-bar > li.complete:before,
.step-bar > li.complete:after {
  background-color: #5cb85c;
}

.step-bar > li.complete:hover {
  background: #449d44;
}

.step-bar > li.complete:hover:before,
.step-bar > li.complete:hover:after {
  background-color: #449d44;
}

.step-bar > li.danger {
  background-color: #f44336;
}

.step-bar > li.danger:before,
.step-bar > li.danger:after {
  background-color: #f44336;
}

.step-bar > li.danger:hover {
  background: #c9302c;
}

.step-bar > li.danger:hover:before,
.step-bar > li.danger:hover:after {
  background-color: #c9302c;
}

.step-bar > li.warning {
  background-color: #fd8c4a;
}

.step-bar > li.warning:before,
.step-bar > li.warning:after {
  background-color: #fd8c4a;
}

.step-bar > li.disabled {
  pointer-events: none;
}

.step-bar > li.disabled > span {
  color: transparent;
}

@media (min-width: 768px) {
  .step-bar > li {
    line-height: 32px;
    padding: 0 24px 0 16px;
  }

  .step-bar > li:before,
  .step-bar > li:after {
    left: -8px;
    width: 16px;
    height: 16px;
  }

  .step-bar > li:after {
    top: 16px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .step-bar > li:first-child {
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .step-bar.sm > li {
    line-height: 24px;
    padding: 0 18px 0 12px;
  }

  .step-bar.sm > li:before,
  .step-bar.sm > li:after {
    left: -6px;
    width: 12px;
    height: 12px;
  }

  .step-bar.sm > li:after {
    top: 12px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .step-bar.sm > li:first-child {
    padding-left: 12px;
  }
}

.step-bar.sm > li {
  font-size: 12px;
}

@media (min-width: 768px) {
  .step-bar.md > li {
    line-height: 40px;
    padding: 0 30px 0 20px;
  }

  .step-bar.md > li:before,
  .step-bar.md > li:after {
    left: -10px;
    width: 20px;
    height: 20px;
  }

  .step-bar.md > li:after {
    top: 20px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .step-bar.md > li:first-child {
    padding-left: 20px;
  }
}

.step-bar.md > li {
  font-size: 14px;
}

@media (min-width: 768px) {
  .step-bar.lg > li {
    line-height: 80px;
    padding: 0 60px 0 40px;
  }

  .step-bar.lg > li:before,
  .step-bar.lg > li:after {
    left: -20px;
    width: 40px;
    height: 40px;
  }

  .step-bar.lg > li:after {
    top: 40px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .step-bar.lg > li:first-child {
    padding-left: 40px;
  }
}

.step-bar.lg > li {
  font-size: 16px;
}

/* Salesforce style reset */
ul li,
ol li {
  margin: 0;
}

/* CodePen Stuff */
body {
  font-family: sans-serif;
}

hr {
  margin: 3rem 0;
  border-top: 0;
  border-bottom: 1px solid #ccc;
}

code {
  font-size: 18px;
  background: #eee;
  padding: 0 4px;
  color: #f44336;
}

.file-input-drag {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-input-drag:focus {
  outline: none;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-1 {
  font-size: 2.5rem !important;
}

.fs-flag-icon {
  font-size: 17px !important;
}
.min-w-50 {
  min-width: 50%;
}

.max-w-50 {
  max-width: 50%;
}

.size-badge-icon {
  width: 62px;
  height: 62px;
}

.color-font-progress-success {
  color: #02c9a6 !important;
}

.min-h-stack {
  min-height: 211px;
  height: 100%;
}

.min-h-stack-footer {
  min-height: 55px;
  height: 100%;
}

.modal-dialog-custom {
  max-width: 55% !important;
}

.decoration-underline {
  color: #000;
  text-decoration: underline !important;
}

.decoration-underline:hover {
  text-decoration: underline;
}

.variant-input {
  border: 0.5px solid #d6dbeb !important;
  border-radius: 5px;
  background: transparent;
}

.hide {
  opacity: 0;
}

.show-on-hover:hover .hide {
  opacity: 1;
}

.donwload-color {
  color: #71869d !important;
}

.donwload-color:hover {
  color: #092ace !important;
}

.circular-progress-small {
  width: 30px;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fw-normal {
  font-weight: normal;
}

.with-search-icon {
  width: 300px;
}

.prospect-table tbody tr td {
  padding: 12px !important;
}

.prospect-table tr:has(.expand-collapse):hover .expand-collapse a i {
  color: var(--deleteDarkColor) !important;
}

.tr-no-hover {
  background: rgba(140, 152, 164, 0.05) !important;
  color: black !important;
}
.prospect-table tbody tr td {
  vertical-align: top;
}

.prospect-table tbody tr td:last-child {
  text-align: right;
}

.prospect-table thead th {
  padding-left: 12px !important;
}

.prospect-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.prospecting-pills a:not(.pill-active):hover {
  background-color: hsl(var(--primaryColorHsl), 100%, 97%) !important;
}
.pill-active {
  background: var(--primaryColor) !important;
}

.pill-active:hover {
  filter: brightness(80%);
  background: var(--primaryColor) !important;
  color: white !important;
}

.prospect-table tbody tr td:last-child,
.prospect-table tbody tr td:first-child {
  vertical-align: middle;
}

.page-header-divider {
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
}

.header-logo {
  display: none;
}

@media (min-width: 1199.98px) {
  .header-logo {
    display: block;
  }
}

.more-actions-sm {
  width: 24px !important;
  height: 24px !important;
}

.feed-report-icon {
  height: 45px !important;
  width: 36px !important;
}

.size-avatar-comment {
  width: 43px !important;
  height: 38px !important;
}

.fs-italic {
  font-style: italic;
}

.w-90 {
  width: 90% !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.simple-search {
  padding: 0 !important;
  border-width: 0 !important;
}

.dropdown-prospecting {
  z-index: 99;
}

.hoverLink:hover {
  color: #092ace;
  text-decoration: underline;
}

.font-size-description-field {
  font-size: 14px !important;
}

.idftextarea {
  height: 111px !important;
}

.chart-container {
  max-width: 1220px;
}

.chart-container > div {
  height: 400px;
}

.rounded-top-right-1 {
  border-top-right-radius: 5px !important;
}

.rounded-bottom-right-1 {
  border-bottom-right-radius: 5px !important;
}

.input-dropGroup {
  height: 44px !important;
}

.modal-avatar-upload {
  width: 368px !important;
}

.avatar-size-modal {
  width: 250px !important;
  height: 250px !important;
}

.avatar-size-modal svg {
  height: 5em;
  width: 5em;
}

.size-icon-avatar {
  font-size: 100px !important;
}

.avatar-editor {
  width: 250px !important;
  height: 250px !important;
  border: 1px solid black !important;
  border-radius: 200px !important;
}

.w-49 {
  width: 49%;
}

.td-table > tbody > tr > td {
  padding: 10px 21px;
}

.avatar-style-default {
  height: 300 !important;
  width: 100% !important;
  border: '1px solid black' !important;
}

.sidebar-min-height {
  background: #0c183d;
  min-height: 100vh;
}

.fs-74 {
  font-size: 74px;
}

.avatar-xsm {
  width: 1.7rem !important;
  height: 1.7rem !important;
}

.avatar-dark {
  background: var(--secondaryColor);
  color: #fff;
}

.avatar-light {
  background: hsl(var(--primaryColorHsl), 100%, 97%);
  color: var(--secondaryColor);
}
.avatar-light:hover {
  transform: scale(1.2) !important;
}

.min-width-220 {
  min-width: 220px !important;
}

.min-width-200 {
  min-width: 200px;
}

.w-260 {
  width: 260px;
}

.p-drop-menu {
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
}

.size-logo-login {
  max-width: 160px !important;
  height: 53px !important;
}

.forget-label {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.modal-xs {
  width: 400px !important;
}

.modal-xss {
  width: 350px !important;
}

.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}

.rank-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-1 {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;

  background: linear-gradient(146.47deg, #fbf0b6 -26.46%, #f2ae54 117.22%);
}

.rank-2 {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;

  background: linear-gradient(150.1deg, #dde4f5 -20.32%, #a6b2cb 114.86%);
}

.rank-3 {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;

  background: linear-gradient(144.46deg, #f9e7d0 -7%, #ae734d 116.33%);
}

.divider-horizontal {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-dialog-deals {
  max-width: 65% !important;
}

.pos-icon-schedule {
  position: absolute;
  left: -20px !important;
  top: 10px;
}

.pos-icon-schedule.more-activity {
  left: -23px !important;
  top: 5px;
}

.e-date-icon,
.e-time-icon {
  display: none !important;
}

.pos-icon-inside-imput {
  position: absolute;
  top: 10px;
  left: 10px;
}

.fw-bolder {
  font-weight: bolder !important;
}

.font-inter {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.idf-tooltip > .tooltip-inner {
  background-color: var(--secondaryColor) !important;
}

.idf-tooltip > .arrow::before {
  border-top-color: var(--secondaryColor) !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.container-fluid {
  padding: 1rem !important;
}

.abs-center-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.abs-center {
  left: 50%;
  transform: translate(-50%);
}

.abs-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.text-green-900 {
  color: var(--wonDarkColor) !important;
}

.spinner-grow-xs {
  width: 16px !important;
  height: 16px !important;
}

.spinner-grow-sm2 {
  width: 24px !important;
  height: 24px !important;
}

.btn-icon-sm {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.height-30 {
  height: 50px;
  width: 120px !important;
}

.list-group.item-border-bottom li {
  border-bottom: 1px solid #eee !important;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  background-clip: padding-box;
  height: 100%;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 10px;
}

.my-masonry-grid_column_2:nth-child(2n) {
  width: 100% !important;
}

/*Public Sidebar css*/
.public-sidebar {
  background: #fafbfc;
  border-right: 1px solid #d6dbeb;
  height: 100%;
  padding: 50px 30px;
  text-align: center;
}

.public-sidebar .inner-content .account-balance {
  font-size: 90px;
  color: #0366d6;
}

.public-sidebar .inner-content h2 {
  font-style: normal;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
}

.public-sidebar .inner-content h5 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.public-sidebar .inner-content h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-top: 15px;
  color: #000000;
}

/*public page main content*/
.public-main-content .page-header h1 {
  display: flex;
  align-items: center;
  gap: 17px;
}

.public-main-content .page-header h1 span {
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #092ace;
  margin-right: 20px;
}

.public-main-content .nav-tabs .nav-item {
  width: 50% !important;
  text-align: center;
}

.public-main-content .nav-tabs .nav-item a.active {
  color: #092ace !important;
}

.public-main-content .nav-tabs .nav-item a {
  color: #000 !important;
  /* padding: 0.65rem 1rem; */
}

.public-main-content .nav-tabs .nav-item a div {
  justify-content: center;
}

.public-file-heading {
  color: #000 !important;
}

.inner-public-main-content h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.inner-public-main-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #97a4af;
}

.public-drag-drop .dropzone-custom {
  min-height: 165px;
  display: flex;
  border-radius: 9px;
  border-color: #b8c3c9 !important;
}

.public-drag-drop .pt-3 {
  padding-top: 0px !important;
}

.public-drag-drop #dropdown-autoclose-true {
  display: none;
}

.public-drag-drop .public-page-setting {
  display: grid;
  margin: 0px !important;
}

.public-drag-drop .public-page-setting .position-relative {
  order: 2;
  margin: 20px 15px 0px;
}

.play-icon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
}

.public-video h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 0px;
}

.public-video p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (min-width: 992px) {
  .public_profile .col-lg-2 {
    flex: 0 0 18.66667% !important;
    max-width: 18.66667% !important;
  }

  .public_profile .col-lg-10 {
    flex: 0 0 81.33333% !important;
    max-width: 81.33333% !important;
  }
}

.report-sticky-controls {
  display: none !important;
}

.report-block:hover .report-sticky-controls {
  display: inline-block !important;
}

.font-size-xl2 {
  font-size: 1.4rem !important;
}
.doubleBorderB {
  border-bottom: 2px double #e5ebef;
}
.resizing-none {
  resize: none;
}
.btn-back {
  padding: 7px 10px !important;
  background-color: #ffffff !important;
  border: 1px solid #d6dbeb !important;
  border-radius: 5px;
  width: 46px;
  height: 35px;
}

.btn-back span {
  color: black;
  font-size: 20px;
}

.cr-p {
  cursor: pointer;
}

.btn-back:hover {
  background-color: #092ace !important;
  transition: all 0.6s ease;
  border-color: #092ace;
}

.btn-back:hover span {
  color: white !important;
}

.video-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.custom-switch-setting .custom-switch {
  transform: scale(1) !important;
}

.switch-setting-1 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  position: relative;
  width: 100%;
}

.switch-setting-1::after {
  content: '';
  background-image: url('https://cdn-icons-png.flaticon.com/512/60/60995.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 10px;
  height: 10px;
}

.switch-setting {
  background-color: white;
  border-radius: 7px;
  width: 200px;
  display: block;
  position: absolute;
  top: 25px;
  padding: 10px 15px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}

.switch-setting span {
  padding-left: 10px;
}

.switch-setting-main .card-title {
  border-bottom: 0px !important;
}

.letter-image {
  background-color: #e4e6e9;
  color: #4284e3;
  font-size: 15px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: 700;
  margin-right: 10px;
}

.teams-page .col-sm {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

/*Tree View Table*/
.btn_hover_hide {
  opacity: 0 !important;
  visibility: hidden !important;
  display: flex;
  align-items: center;
  gap: 1.2em;
}
.btn_hover_hide img {
  cursor: pointer !important;
}
.btn_hover_hide:focus,
.btn_hover_hide:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.btn_hover_show:hover .action.btn_hover_hide {
  opacity: 1 !important;
  visibility: visible !important;
  transition: all 0.7s ease !important;
}
.btn_hover_hide button {
  background-color: transparent !important;
  border: none !important;
  width: 50px !important;
  font-size: 20px !important;
}
.btn_hover_hide button i {
  margin-top: -20px;
}
.main-row-hh > *:first-child::before {
  z-index: 2 !important;
  content: ' ' !important;
  height: 17px !important;
  width: 17px !important;
  border: 2px solid var(--tree-item) !important;
  display: inline-block !important;
  text-align: center !important;
  line-height: 10px !important;
  margin-right: 9px !important;
  border-radius: 2px !important;
  color: #979595 !important;
  margin-left: -10px !important;
  background: white !important;
  position: absolute !important;
  top: 5px !important;
  left: 11px !important;
}

.show_plus_icon:not(.no-children-h) > *:first-child::before {
  content: '+' !important;
}
.show_minus_icon:not(.no-children-h) > *:first-child::before {
  content: '-' !important;
}
#no-more-tables .table td,
#no-more-tables .table th {
  border-top: 0 !important;
}
.main-row-hh > *:first-child {
  position: relative !important;
}
.main-body-hh {
  position: relative !important;
}
.main-body-hh.show_border:not(.no-children-h-main):before {
  position: absolute;
  content: '';
  border-left: 2px dashed var(--tree-line);
  z-index: 1;
  left: 7px;
  top: 15px;
  height: calc(100% - 35px);
  min-height: 38px;
}
.child .table-tree-body-cell:nth-child(1)::after {
  position: absolute;
  content: '';
  border-bottom: 2px dashed var(--tree-line);
  height: 2px;
  width: 10px;
  left: -7px;
  display: block;
  top: 14px;
}
.child .main-body-hh > .main-body-hh:last-child:after {
  content: '';
  border-left: 2px solid #ffffff;
  height: calc(100% - 25px);
  position: absolute;
  left: -11px;
  top: 25px;
  z-index: 1;
}
.table-tree-tbody-row:hover {
  background: #f5f8fa;
}
.table-tree-tbody-row {
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
}
.table-tree-tbody-row:hover:before {
  content: '';
  height: 100%;
  width: calc(100% + 1000px);
  position: absolute;
  background: #f5f8fa;
  left: -500px;
  top: 0;
  bottom: 0;
}
/* table-tree div bases */
.table-tree-thead-row {
  border-color: rgba(231, 234, 243, 0.7);
}
.table-tree-thead-row,
.table-tree-tbody-row {
  display: flex;
  border-bottom: 1px solid #f0f5f8;
}
.table-tree-thead-row .table-head-cell {
  padding: 1px 11px;
  border-bottom: 2px solid rgba(231, 234, 243, 0.7);
  border-top: 1px solid rgba(231, 234, 243, 0.7);
  border-left: 1px solid rgba(231, 234, 243, 0.7);
  display: table-cell;
  box-sizing: border-box;
  overflow-wrap: break-word;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  white-space: nowrap;
  background-color: var(--greyTableHead);
  vertical-align: middle;
  z-index: 2;
}
.table-tree-body-cell {
  padding-left: 2rem;
  position: relative;
}
.table-tree-thead-row .table-head-cell:nth-child(1),
.table-tree-tbody-row .table-tree-body-cell:nth-child(1) {
  width: calc(100% - 465px);
}
.table-tree-thead-row .table-head-cell:nth-child(2),
.table-tree-tbody-row .table-tree-body-cell:nth-child(2) {
  width: 130px;
}
.table-tree-thead-row .table-head-cell:nth-child(3),
.table-tree-tbody-row .table-tree-body-cell:nth-child(3) {
  width: 165px;
}
.table-tree-thead-row .table-head-cell:nth-child(4),
.table-tree-tbody-row .table-tree-body-cell:nth-child(4) {
  width: 170px;
}
.table-tree-tbody-row .table-tree-body-cell:nth-child(1) {
  padding-left: 29px !important;
}
.main-body-hh-table-body {
  padding: 0px 24px;
  overflow: hidden;
}
.table-head-cell {
  display: flex !important;
  align-items: center;
  border: none !important;
  font-weight: 600;
}
.table-tree {
  border-radius: 10px;
}
.treeBtn {
  background-color: transparent;
  height: 45px;
  display: flex;
  align-items: center;
  border: none !important;
  overflow: hidden;
}
.dropdownTree .table-tree-body-cell::before {
  display: none !important;
}
.dropdownTree .show_border:last-child .table-tree-body-cell::after {
  display: none !important;
}
.dropdownTree .table-tree-body-cell:nth-child(1) {
  padding-left: 0px !important;
}
.dropdownTree .btn_hover_show,
.dropdownTree .show_border:last-child .table-tree-body-cell {
  position: relative;
}
.dropdownTree {
  overflow: hidden;
}
.dropdownTree .main-body-hh.show_border:not(.no-children-h-main):before {
  left: 1px;
  top: 28px;
  height: calc(100% - 49px);
  min-height: unset;
}
.dropdownTree .child .table-tree-tbody-row::after {
  position: absolute;
  content: '';
  border-bottom: 2px dashed #999;
  height: 2px;
  width: 10px;
  left: -14px;
  display: block;
  top: 22px;
}
.disabled,
.disabled * {
  cursor: auto;
  pointer-events: none;
  color: #bfbfbf;
}
.dropdownTree {
  border: 0.0625rem solid #e7eaf3;
  padding: 10px 15px;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}
.dropdownTree h5 {
  font-size: 14px;
  font-weight: 400;
}
.dropdownTree:focus {
  box-shadow: 0 0 10px rgb(9 42 206 / 10%) !important;
  background-color: #fff;
  border-color: rgba(9, 42, 206, 0.4) !important;
  outline: 0;
  color: var(--black);
}
.teams-page .btn-outline-danger i {
  color: #f44336 !important;
}
.teams-page .btn-outline-danger:hover i {
  color: white !important;
  transition: all 0.5s ease;
}
.teams-page .btn-outline-primary i {
  color: #083ca5 !important;
}
.teams-page .btn-outline-primary:hover i {
  transition: all 0.5s ease;
  color: white !important;
}

.-top-55 {
  top: -44px;
  width: 60% !important;
  right: 10px;
}
.button-outer.-top-55 {
  right: 1.5rem;
  top: -51px;
}
.top-8 {
  top: 8px;
}
.top-10 {
  top: 10px;
}
.top-12 {
  top: 12px;
}
.top-47 {
  top: 47px !important;
}
.-top-48 {
  top: -48px;
}
.right-10 {
  right: 25px;
}
.right-20 {
  right: 22px;
}
.-top-65 {
  top: -62px;
}

.-top-54 {
  top: -54px;
}
.left-4 {
  left: 4px;
}
.left-8 {
  left: 8px;
}

.left-17 {
  left: 17px;
}

.-bottom-26 {
  bottom: -26px;
}

.pointer-event {
  pointer-events: none !important;
  top: -46px;
}

/*workflow css*/

.edit_workflow {
  background: #e4f1eb;
  visibility: hidden;
  transition-duration: 200ms;
  transition-property: all;
  opacity: 0;
}

.workflow_box {
  border: 2px solid lightgray;
  transition-duration: 200ms;
  transition-property: all;
  position: relative;
}
.workflow_box:hover {
  transition: border 0.5s ease-in-out;
  border-color: #061b85 !important;
}
.workflow_box:hover .edit_workflow {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}
.workflow_box .nav {
  border-bottom: 1px solid lightgray;
}
.workflow_box .nav a {
  color: black !important;
}
.workflow_box .nav a.active {
  border-bottom: 2px solid #061b85;
  color: #061b85 !important;
}
.step-2::after {
  content: '';
  height: 34px;
  width: 2px;
  background-color: #061b85;
  position: absolute;
  top: -34px;
  left: 30px;
}
.step-3::after {
  content: '';
  height: 2px;
  width: 35px;
  background-color: #061b85;
  position: absolute;
  top: 30px;
  left: -35px;
}

.condition-input select {
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.condition-input input,
.condition-input:nth-child(3) select {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.count {
  height: 30px;
  width: 30px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  top: -45px;
}

.dropdown-search-v2 .dropdown-menu {
  margin-top: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.dropdown-search-v2 .dropdown-item {
  padding: 8px 0 8px 0;
}

.dropdown-search-v2 .dropdown-item.disabled,
.dropdown-search-v2 .dropdown-item.rbt-menu-custom-option {
  padding: 10px 10px 10px 15px;
}

.dropdown-search-v2 .col {
  padding-left: 0 !important;
}

.vertical-section-board {
  height: calc(100vh - 126px) !important;
  box-sizing: border-box;
}
.vertical-section-board .vertical-section-chk-list .card .border-top {
  border: none !important;
}
.stages-board {
  height: calc(100vh - 70px) !important;
  box-sizing: border-box;
}

.pipeline-board-edit-form {
  height: calc(100vh - 250px) !important;
  box-sizing: border-box;
}

.vertical-section {
  flex: 0 0 32%;
  min-width: 32% !important;
  max-width: 32% !important;
}

.vertical-section-chk-list {
  flex: 0 0 34%;
  min-width: 34% !important;
  max-width: 34% !important;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.lkjDVC svg,
.lkjDVC .file-types {
  display: none;
}

.lkjDVC {
  max-width: 100% !important;
  height: 90px !important;
  background-color: #f8fafd !important;
}
.sc-hLBbgP {
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
}
.dmajdp > span {
  color: #000000 !important;
}
.sc-hLBbgP span {
  color: var(--secondaryColor) !important;
}

.lkjDVC svg,
.lkjDVC .file-types {
  display: none;
}

.lkjDVC {
  max-width: 100% !important;
  height: 90px !important;
  background-color: #f8fafd !important;
}
.sc-hLBbgP {
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
}
.dmajdp > span {
  color: #000000 !important;
}
.sc-hLBbgP span {
  color: var(--secondaryColor) !important;
}

.spGlobal-img img {
  width: 85px !important;
}

.fasterPayments-img img {
  width: 68px !important;
}

.text-gray-eee {
  color: #eee;
}

.bottom-40 {
  bottom: 40px;
}

.bottom-15 {
  bottom: 15px;
}

.right-bar-vh {
  height: calc(100vh - 138px);
}
.dropdown-menu-setting {
  transform: none !important;
}

.list-disc {
  list-style-type: disc;
}

.list-disc li {
  list-style-type: disc;
}

.text-strikethrough,
.text-strikethrough:hover {
  text-decoration: line-through !important;
}
.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 121;
}
.activity-date-picker .react-datepicker-wrapper {
  width: 45% !important;
}
.abs-dt .react-datepicker-wrapper {
  width: 100%;
}

.stage-dd .dropdown-menu {
  transform: translate3d(-105px, 32px, 0px) !important;
}
.stage-dd .dropdown-menu.lost {
  transform: translate3d(10px, 32px, 0px) !important;
}

.abs-dt .react-datepicker__triangle::after,
.abs-dt .react-datepicker__triangle::before {
  left: 113px !important;
}
.bg-blue-soft {
  background: var(--blueSoftBgColor) !important;
}

.bg-blue-dark {
  background: var(--colorAInstitute) !important;
}
.step.step-icon-sm.mt-0 > div {
  width: 100%;
}

.transition {
  transition: all 0.2s ease-in;
}

.bg-gray-2 {
  background-color: var(--greyColor) !important;
}
.bg-gray-6 {
  background-color: #b4c3d10d !important;
}
.bg-gray-6:focus {
  background-color: #fff !important;
}
.bg-gray-table-head {
  background-color: var(--greyTableHead) !important;
}
.bg-setting-menu {
  background: var(--setting-menu-color) !important;
}
.text-danger-soft {
  color: var(--lostDarkColor);
}
.characters-check {
  position: absolute;
  top: 50%;
  right: 24px;
  font-size: 12px;
  color: rgb(100, 100, 100);
  transform: translateY(-50%);
}
.clear-field {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 121;
  font-size: 12px;
  color: var(--icon-text-color) !important;
  transform: translateY(-50%);
}
.rbt-menu.dropdown-menu.show,
#dropdown-tags.show {
  transform: translate3d(0, 42px, 0px) !important;
}

.option-hover {
  transition: all 0.2s linear;
}

.option-hover:hover {
  border-color: var(--secondaryColor);
  cursor: pointer;
}

.option-hover.selected {
  border-color: var(--secondaryColor);
}

.option-hover.selected .checked-icon {
  color: var(--secondaryColor);
}

.option-hover.ai:hover {
  border-color: var(--colorAInstitute);
  cursor: pointer;
}

.option-hover.ai.selected {
  border-color: var(--colorAInstitute);
}

.option-hover.ai.selected .checked-icon {
  color: var(--colorAInstitute);
}

.text-ai {
  color: var(--colorAInstitute);
}

.sa-results.ai a.font-size-sm2.font-weight-bold {
  color: var(--colorAInstitute) !important;
}

.sa-results.ai a.font-size-sm2.font-weight-bold:hover {
  color: var(--blueSoftColor) !important;
}

.blinker {
  border-left: 0.5em solid #777;
  animation: blink 0.4s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.markdown:not(.finished) :last-child::after {
  content: '';
  display: inline-block;
  width: 0.5em;
  height: 1em;
  position: relative;
  margin-left: 0.2em;
  background-color: #777;
  animation: blink-caret 0.75s step-end infinite;
  top: 2px;
}
.add-title {
  color: #777;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}
.add-title:hover {
  color: #061b85 !important;
}
.input-title {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
  margin-top: -10px !important;
}
.input-title:focus {
  box-shadow: none !important;
  outline: none !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.e-date-wrapper {
  border: none !important;
}
.calendar-activity {
  border: 1px solid #e7eaf3 !important;
  min-height: 35px !important;
  padding: 4px 10px !important;
  border-radius: 5px !important;
}
.SimpleMentionEditor_editor__V8sju {
  transition: all 200ms ease;
  border: 1px solid lightyellow !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.SimpleMentionEditor_editor__V8sju:has(.public-DraftEditorPlaceholder-hasFocus),
.hasFocus,
.SimpleMentionEditor_editor__V8sju:has(.input-title:focus) {
  border: 1px solid rgba(9, 42, 206, 0.4) !important;
}
.editor_setting {
  background: #f3f3f3;
  padding: 15px;
  border-radius: 8px;
}
.editor_setting.new_note {
  background: #fff;
  padding: 15px;
  border-radius: 0;
}
.new_note .public-DraftEditor-content span {
  color: #677788 !important;
}
.new_note .notranslate.public-DraftEditor-content span {
  color: var(--black) !important;
}
.bottom-states {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  padding-left: 30px !important;
}
.public-DraftStyleDefault-orderedListItem {
  list-style: numeric !important;
}
.public-DraftStyleDefault-unorderedListItem {
  list-style: disc !important;
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #444;
  }
}

mux-player {
  --seek-backward-button: none;
  --seek-forward-button: none;
  --pip-button: none;
  --playback-rate-button: none;
}

.rpt-cover {
  background-image: linear-gradient(
      hsla(var(--primaryColorHsl), 100%, 10%, 0.9),
      hsla(var(--primaryColorHsl), 100%, 10%, 0.9)
    ),
    url('https://iili.io/Hi61cdP.jpg');
  height: 892px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.rpt-cover.working-capital {
  background-image: linear-gradient(
      hsla(var(--primaryColorHsl), 100%, 10%, 0.9),
      hsla(var(--primaryColorHsl), 100%, 10%, 0.9)
    ),
    url('https://iili.io/Hi61l71.jpg');
}

.rpt-cover-excel {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url(https://iili.io/HU7xkQe.jpg);
  height: 892px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.rpt-logo {
  background: url(https://iili.io/HvYYdlt.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  height: 50px;
  width: 200px;
  image-rendering: crisp-edges;
}

.diff-cover {
  background: url(https://iili.io/HvYaja9.png);
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}

.diff-cover-excel {
  background: url(https://iili.io/HUDVJl1.png);
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}
.payable-cover {
  background: url(https://iili.io/HvYaavn.png);
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}
.payable-cover-excel {
  background: url(https://iili.io/HUDMPK7.png);
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}
.receivable-cover {
  background: url(https://iili.io/HkbeaKF.jpg);
  height: 150px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}

.text-line-through {
  text-decoration: line-through;
}

.wc-opportunity-cover {
  background: url(https://iili.io/HiS1U9j.png);
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: cover;
  background-size: 100% auto;
  position: relative;
  image-rendering: crisp-edges;
}

.report-tile {
  transition: all 0.2s ease-in;
  display: flex;
  flex-flow: column;
  justify-content: top;
  align-items: center;
}

.report-tile-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insights {
  background: var(--rpt-tile-in-bg) !important;
  i {
    color: var(--rpt-tile-in-icon) !important;
  }
}

.bg-pink-dark {
  background-color: var(--rpt-tile-in-icon) !important;
}

.treasury {
  background: var(--rpt-tile-tm-bg);
  &.forced {
    background: var(--rpt-tile-tm-bg) !important;
  }
  i {
    color: var(--rpt-tile-tm-icon) !important;
  }
}

.report-tile.treasury:hover,
.treasury.dropdown-item:hover {
  background: var(--rpt-tile-tm-bg) !important;
}

.working-capital {
  background: var(--rpt-tile-wc-bg);
  i {
    color: var(--rpt-tile-wc-icon) !important;
  }
}
.report-tile.working-capital:hover,
.working-capital.dropdown-item:hover {
  background: var(--rpt-tile-wc-bg) !important;
}

.merchant {
  background: var(--rpt-tile-mc-bg);
  &.forced {
    background: var(--rpt-tile-mc-bg) !important;
  }
  i {
    color: var(--rpt-tile-mc-icon) !important;
  }
}
.report-tile.merchant:hover,
.merchant.dropdown-item:hover {
  background: var(--rpt-tile-mc-bg) !important;
}

.fraud {
  background: var(--rpt-tile-fr-bg);
  i {
    color: var(--rpt-tile-fr-icon) !important;
  }
}
.report-tile.fraud:hover,
.fraud.dropdown-item:hover {
  background: var(--rpt-tile-fr-bg) !important;
}

.commercial-card {
  background: var(--rpt-tile-cc-bg);
  &.forced {
    background: var(--rpt-tile-cc-bg) !important;
  }
  i {
    color: var(--rpt-tile-cc-icon) !important;
  }
}
.report-tile.commercial-card:hover,
.commercial-card.dropdown-item:hover {
  background: var(--rpt-tile-cc-bg) !important;
}

.account-structure {
  background: var(--rpt-tile-as-bg);
  i {
    color: var(--rpt-tile-as-icon) !important;
  }
}

.report-tile.account-structure:hover,
.account-structure.dropdown-item:hover {
  background: var(--rpt-tile-as-bg) !important;
}

.custom-dd-select .dropdown-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.custom-dd-select .dropdown-toggle.text-black:hover {
  color: var(--whiteHoverBlue) !important;
}

.w-fit {
  width: fit-content;
}

.tooltipModal {
  max-height: 160px !important;
  overflow-y: auto !important;
  max-width: 450px !important;
  transition-duration: 0.15s !important;
  padding: 10px;
  border-radius: var(--borderRadius);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0 !important;
}
.tooltipMain {
  position: absolute;
  z-index: 212;
  top: 35px;
  background: #fff;
  border-radius: var(--borderRadius);
  width: 300px;
}
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 30px;
}
.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}

.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 30px;
}
.stats {
  /* width: 100%; */
  bottom: 0;
  z-index: 21;
  max-width: 85.5%;
}
.table-stats-loading {
  padding: 9px 10px 9px 24px;
}
.table-pagination-loading {
  padding: 9px 24px 9px 10px;
}
.statsDiv {
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
}
.stats ul {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
  gap: 24px;
  margin-bottom: 0 !important;
  /* background: white; */
  list-style-type: none;
}
.stats ul li {
  font-size: 13px;
  font-weight: 600;
  /* color: var(--text-gray); */
  /* min-width: 100px; */
}
.stats ul li span {
  font-size: 13px;
  font-weight: 600;
}
.ArrowIcon {
  width: 11px;
  left: 12px;
  top: -6px;
  height: 11px;
  position: absolute;
  background: #fff;
  transform: rotate(45deg);
  display: block;
}
.disabled {
  opacity: 0.5;
}
.idf-custom-file::file-selector-button {
  background: var(--secondaryColor);
  color: #fff;
  border-radius: 30px;
  border: 1px solid var(--secondaryColor);
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.idf-custom-file::file-selector-button:hover {
  filter: brightness(80%);
}

.text-white-children > * {
  color: #fff !important;
}

.text-white-children i:hover,
.text-white-children a:hover {
  color: #ccc !important;
}

.bg-hover-white-primary:hover {
  background: #fff !important;
  color: var(--primaryColor) !important;
  filter: brightness(100%) !important;
}

.bg-hover-danger:hover {
  background: var(--lostDarkColor) !important;
  color: #fff;
}
.bg-hover-danger:hover * {
  background: var(--lostDarkColor) !important;
  color: #fff !important;
}

.bg-hover-success:hover {
  background: var(--wonDarkColor) !important;
  color: #fff;
}
.bg-hover-success:hover * {
  background: var(--wonDarkColor) !important;
  color: #fff !important;
}

.report-controls {
  right: 16px;
}

.left-150 {
  left: 150px;
}

.date-wrapper .react-datepicker-wrapper {
  display: block !important;
}
.text-purpel {
  color: #7f56d9 !important;
}
.bg-purpel {
  color: #7f56d9 !important;
  background: #f4ebff !important;
}
.font-size-mini {
  font-size: 9px !important;
}

.show-all {
  background: #eeeffc;
  color: var(--secondaryColor) !important;
  padding: 7px 10px;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.card-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100px;
  border-radius: 10px;
  background-color: var(--secondaryColor) !important;
  color: white;
}
.product-lead-cards {
  min-height: 150px;
  background: linear-gradient(
    180deg,
    rgb(234 236 255 / 59%) 11%,
    rgba(255, 255, 255, 1) 60%
  );
  color: black;
}
.mh-520 {
  min-height: 505px;
}
.mh-420 {
  min-height: 425px;
}
.visibility-size {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visibility-size .material-symbols-rounded.fs-5 {
  font-size: 14px;
}

.bg-theme-color {
  background-color: var(--secondaryColor);
}
.text-theme-color {
  color: var(--secondaryColor);
}

.glossary-items .glossary-item:last-child {
  border: 0 !important;
  padding-bottom: 0 !important;
}

.report-widget.no-border > .card-body {
  border-radius: var(--rpt-widget-border-radius) !important;
}
.report-widget:not(.no-border),
.report-widget-inner-section {
  transition: all 0.2s ease-in;
  border: var(--rpt-widget-border-thickness) solid
    var(--rpt-widget-border-color) !important;
  box-shadow: none !important;
}

.report-widget-rounded {
  border: var(--rpt-widget-border-thickness) solid
    var(--rpt-widget-border-color) !important;
  border-radius: var(--rpt-widget-border-radius) !important;
}

.rpt-border-bottom {
  border-bottom: var(--rpt-widget-border-thickness) solid
    var(--rpt-inner-section-border-color) !important;
}
.rpt-border-right {
  border-right: var(--rpt-widget-border-thickness) solid
    var(--rpt-inner-section-border-color) !important;
}

.border-primary-soft {
  border-color: hsl(var(--primaryColorHsl), 100%, 97%) !important;
}

.rpt-border-primary-soft {
  border-width: 2px;
  border-style: solid;
  border-color: hsl(var(--primaryColorHsl), 100%, 97%) !important;
}

.rpt-border-bottom-primary {
  border-bottom: var(--rpt-widget-border-thickness) solid
    hsl(var(--primaryColorHsl), 100%, 91%) !important;
}
.rpt-border-right-primary {
  border-right: var(--rpt-widget-border-thickness) solid
    hsl(var(--primaryColorHsl), 100%, 91%) !important;
}
.report-widget {
  overflow: hidden;
}
.report-widget-inner-section-soft,
.report-widget.no-border {
  transition: all 0.2s ease-in;
  border: var(--rpt-widget-border-thickness) solid
    hsl(var(--primaryColorHsl), 100%, 95%);
  border-radius: var(--rpt-widget-border-radius) !important;
  box-shadow: none !important;
}

.report-widget-inner-section-cutout {
  border: var(--rpt-widget-border-thickness) solid #eee;
  border-radius: var(--rpt-widget-border-radius) !important;
  box-shadow: none !important;
  &.green {
    border-color: #dff0dd;
    .rounded-circle,
    .center-line {
      background: #dff0dd;
    }
    i {
      color: green;
    }
  }
  &.orange {
    border-color: #fce9d4;
    .rounded-circle,
    .center-line {
      background: #fce9d4;
    }
    i {
      color: orange;
    }
  }
  &.red {
    border-color: #ffd6d6;
    .rounded-circle,
    .center-line {
      background: #ffd6d6;
    }
    i {
      color: red;
    }
  }
}

.report-widget-gray-section {
  background: var(--rpt-icon-bg-color) !important;
  border-radius: var(--rpt-widget-border-radius) !important;
  border: var(--rpt-widget-border-thickness) solid
    var(--rpt-widget-border-color) !important;
}

.rpt-rounded {
  border-radius: var(--rpt-widget-border-radius) !important;
}
.report-widget:hover {
  border-style: dashed !important;
}

.report-widget h5,
.report-widget p,
.text-rpt-heading,
.widget-heading-color {
  color: var(--rpt-subtext-color) !important;
}

.report-widget h5 {
  margin-bottom: 10px !important;
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

.report-widget h5[class*="mb-0"] {
  margin-bottom: 0 !important;
}
.report-widget h5[class*="mb-1"] {
  margin-bottom: 4px !important;
}
.report-widget h5[class*="mb-2"] {
  margin-bottom: 8px !important;
}

.working-capital-report .report-widget .widget-extra-padding {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.static-widget:has(.report-widget) .report-widget {
  border: 0 !important;
}

.static-widget:has(.report-widget) .report-widget:hover {
  background: rgba(242, 201, 76, 0.1) !important;
}

.text-green-rpt {
  color: var(--rpt-color-green);
}
#rptPdf .rpt-print {
  margin-bottom: 0.4rem !important;
}

#rptPdf .sub-text {
  font-size: 10px !important;
}

#rptPdf .source-block {
  font-size: 8px !important;
}
#rptPdf .report-widget h5,
#rptPdf h1.text-rpt-heading,
#rptPdf h1.rpt-green-box-heading,
#rptPdf h1.rpt-red-box-heading {
  font-size: 0.9rem !important;
  font-weight: bold !important;
}

#rptPdf .rpt-green-box h1,
#rptPdf .working-capital-report .rpt-red-box-heading {
  font-size: 1.1rem !important;
}

#rptPdf .report-widget p.fs-8,
#rptPdf .report-widget p.font-size-sm2 {
  font-size: 10px !important;
}
.rpt-icon {
  background: var(--rpt-widget-border-color);
  color: var(--rpt-subtext-color);
}

.rpt-icon-lite {
  background: var(--rpt-inner-section-border-color);
  color: var(--rpt-subtext-color);
}

.report-pdf-page .report-widget .list-group {
  margin: 0 !important;
  padding: 0 !important;
}

.report-pdf-page .report-widget .list-group-item {
  background-color: #f8f8fa !important;
  padding: 0 !important;
}

.fti-item::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 50px;
  width: 2px;
  background: var(--rpt-inner-section-border-color);
}

.fti-item.etpr {
  height: 170px;
  width: 2px;
  background: var(--rpt-inner-section-border-color);
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fti-item.etpr.ivr {
  height: 80px;
}

.fti-item.etpr.ivr.bec {
  height: 140px;
}

#rptPdf .fti-item.etpr {
  left: 50%;
  transform: translate(-55%, -50%);
}
#rptPdf .fti-item::after,
#rptPdf .fti-item.etpr::after,
#rptPdf .fti-item.etpr.ivr::after {
  background: var(--rpt-widget-border-color-dark);
}

#rptPdf .fti-item.etpr::after {
  background: var(--rpt-widget-border-color-dark) !important;
  height: 170px !important;
}
#rptPdf .fti-item.etpr.ivr::after {
  background: var(--rpt-widget-border-color-dark) !important;
  height: 80px !important;
}
#rptPdf .fti-item.etpr.ivr.bec::after {
  background: var(--rpt-widget-border-color-dark) !important;
  height: 140px !important;
}
#rptPdf .rpt-green-box {
  border-color: var(--rpt-green-border);
}

.fti-item:last-child::after {
  background: transparent !important;
}
.report-pdf-page .report-widget .card-body,
.report-pdf-page .glossary-widget .card-body,
.report-pdf-page .report-widget .card-body .icon-set-widget {
  padding: 12px !important;
}

#rptPdf .report-widget:not(.no-border),
.report-widget-inner-section {
  border: 2px solid var(--rpt-widget-border-color-dark) !important;
}

#rptPdf .green-red-check-item i {
  position: relative;
  top: -1px;
}
#rptPdf .report-widget-inner-section-soft,
#rptPdf .report-widget.no-border {
  transition: all 0.2s ease-in;
  border: 2px solid hsl(var(--primaryColorHsl), 100%, 92%) !important;
}

#rptPdf .risk-great-icon i {
  position: relative;
  left: -0.5px;
}

.letter-background {
  text-align: left;
  display: inline-block;
  background-color: white; /* Set your desired background color */
  webkit-background-clip: text;
  background-clip: text;
}

.letter-background.text-rpt-green {
  -webkit-text-fill-color: var(--rpt-color-green);
  text-fill-color: var(--rpt-color-green);
  background: linear-gradient(to bottom, #fff, var(--rpt-bg-green) 90%);
}

.letter-background.text-red {
  -webkit-text-fill-color: var(--rpt-color-red);
  text-fill-color: var(--rpt-color-red);
  background: linear-gradient(to bottom, #fff, var(--rpt-bg-red) 180%);
}

.w-200px {
  width: 200px;
}
.w-250px {
  width: 250px;
}
.w-280px {
  width: 280px;
}
.w-100px {
  width: 100px;
}
.w-150px {
  width: 150px;
}
.min-w-185px {
  min-width: 185px;
}

.list-transparent .list-group-item {
  background: transparent !important;
  padding-left: 0 !important;
}

.arrow-align-relative {
  position: relative;
  left: -7px;
}

.wheel-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  text-align: center;
  line-height: 44px;
}

.wheel-icon-lg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  text-align: center;
  line-height: 45px;
}

.material-symbols-rounded.filled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.donut-chart::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  border: 13px solid var(--rpt-color-red);
  border-bottom-color: var(--rpt-donut-light-color);
  transform: rotate(135deg);
  box-sizing: border-box;
}

.donut-chart.wire::before {
  border-right-color: var(--rpt-color-green);
  border-left-color: var(--rpt-donut-light-color);
  border-top-color: var(--rpt-donut-light-color);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.donut-chart.ach::before {
  border-right-color: var(--rpt-color-green);
  border-left-color: var(--rpt-donut-light-color);
  border-top-color: var(--rpt-donut-light-color);
  transform: rotate(-45deg);
  box-sizing: border-box;
}
.donut-chart.ach::after {
  border-right-color: var(--rpt-color-green);
  border-left-color: var(--rpt-donut-light-color);
  border-top-color: var(--rpt-donut-light-color);
  transform: rotate(-45deg);
  box-sizing: border-box;
}
.donut-chart.cards::before {
  border-left-color: var(--rpt-donut-light-color);
  border-top-color: var(--rpt-color-green);
  border-right-color: var(--rpt-donut-light-color);
  transform: rotate(45deg);
  box-sizing: border-box;
}

.donut-chart.cards .after-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  border: 13px solid var(--rpt-donut-light-color);
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(-10deg);
  box-sizing: border-box;
}
.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.coach-tile {
  border: 2px solid;
  border-radius: var(--borderRadius);
  transition: all 0.2s ease-in;
  color: var(--black) !important;
}

.coach-tile:hover {
  color: var(--black) !important;
}
.sales {
  border-color: var(--coach-tile-sales-dark) !important;
  background: var(--coach-tile-sales);
}

.sales.selected {
  background: var(--coach-tile-sales-light) !important;
}
.sales.selected.btn {
  background: var(--coach-tile-sales-dark-btn) !important;
  border-color: var(--coach-tile-sales-dark-btn) !important;
}
.coach-tile.sales:hover {
  background: var(--coach-tile-sales-dark);
}
.sales.selected.btn:hover {
  filter: brightness(90%);
}
.professional {
  border-color: var(--coach-tile-professional-dark) !important;
  background: var(--coach-tile-professional);
}
.professional.selected.btn {
  background: var(--coach-tile-professional-dark-btn) !important;
  border-color: var(--coach-tile-professional-dark-btn) !important;
}
.professional.selected {
  background: var(--coach-tile-professional-light) !important;
}
.coach-tile.professional:hover {
  background: var(--coach-tile-professional-dark);
}
.professional.selected.btn:hover {
  filter: brightness(90%);
}
.industry {
  border-color: var(--coach-tile-industry-dark) !important;
  background: var(--coach-tile-industry);
}
.industry.selected.btn {
  background: var(--coach-tile-industry-dark-btn) !important;
  border-color: var(--coach-tile-industry-dark-btn) !important;
}
.industry.selected {
  background: var(--coach-tile-industry-light) !important;
}
.coach-tile.industry:hover {
  background: var(--coach-tile-industry-dark);
}
.industry.selected.btn:hover {
  filter: brightness(90%);
}
.btn-primary.hover-link:hover {
  color: #fff !important;
  filter: brightness(80%);
}

.public-layout .col-lg-5 {
  max-width: 460px;
}

.avatar-custom-toggle::after {
  display: none !important;
}

.avatar-dropdown-container .menu {
  transform: translate3d(10px, -45px, 0px) !important;
  z-index: 9999;
}

.avatar-custom-toggle .avatar-md .avatar-initials {
  font-size: 14px !important;
}

.stepper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.stepper .step {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--greyColor);
  border: 1px solid #ccc;
  color: #444;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  top: -18px;
  opacity: 0.7;
  position: absolute;
  transform: translateX(-50%);
}

.stepper .active {
  border-color: var(--secondaryColor) !important;
  color: var(--secondaryColor) !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.stepper .completed {
  background-color: var(--wonDarkColor) !important;
  border-color: var(--wonDarkColor) !important;
  color: #fff !important;
  opacity: 1 !important;
}

.step-content {
  margin-top: 20px;
}

.custom-toggle-caret::after {
  position: absolute;
  right: 10px;
  top: 10px;
}

.login-alert-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.login-alert-container .login-alert {
  background: var(--login-alert-green-bg);
  border: 1px solid var(--login-alert-green-border);
}

.flex-column.with-separator div.flex-item:not(:last-child) {
  border-bottom: 1px solid var(--menuBorderColor);
}

.opacity-100 {
  opacity: 100 !important;
  pointer-events: auto !important;
}

.react-flow__node-input,
.react-flow__node-default {
  padding: 0 !important;
  border: none !important;
  border-radius: var(--borderRadiusLg) !important;
}
.react-flow__attribution {
  display: none !important;
}

.report-widget .react-flow__handle {
  visibility: hidden;
}

.account-structure-display-item:empty {
  display: none;
}

.kpi-widget:first-child,
.kpi-widget:nth-child(3n + 1) {
  margin-left: 1rem;
}

.learn-dashboard-activity-table thead th:first-child,
.learn-dashboard-activity-table tbody tr td:first-child {
  padding-left: 16px !important;
}

.modal-header.flex-fill .modal-title {
  flex-grow: 1 !important;
}

.dataTables_wrapper > div.p-0,
.dataTables_wrapper > div.overflow-x-auto {
  min-height: 300px;
}
.dataTables_wrapper {
  height: 100%;
}
.dataTables_wrapper > .accounts-table-container {
  min-height: calc(100vh - 223px) !important;
}
.table-roles > div.overflow-x-auto {
  min-height: 290px;
}
.dataTables_wrapper > .sticky-table-footer + .card-footer {
  padding-block: 7px;
}
.right-panel-setting .card.overflow-x-hidden.overflow-y-auto {
  box-shadow: none !important;
  border: none !important;
}
body .bg-none {
  background: none !important;
}

.dropdown-item.rbt-menu-pagination-option {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.opportunity-list-view-table table tbody tr td:nth-child(4):hover:before,
.opportunity-list-view-table table tbody tr td:last-child:hover::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(140, 152, 164, 0.05) !important;
  z-index: 1;
}
.deal-progress-bar .bg-danger {
  background-color: #f44336 !important;
}

.dropdown-item.rbt-menu-pagination-option {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.day-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  position: absolute;
  bottom: 2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #3f51b5; /* Primary color or any color you want */
}

.calendar-checklist .MuiDateCalendar-root {
  height: 300px;
}

.calendar-checklist .MuiDateCalendar-root {
  width: var(--checkist-sidebar-width) !important;
  margin: 0 !important;
}
.calendar-checklist .MuiPickersDay-today .MuiPickersDay-today {
  background-color: hsl(var(--primaryColorHsl), 90%, 91%) !important;
  border: 1px solid hsl(var(--primaryColorHsl), 100%, 79%) !important;
  color: #000 !important;
}

.calendar-checklist .MuiPickersDay-root:hover {
  background: transparent !important;
}

.calendar-checklist .MuiPickersDay-root .MuiPickersDay-root:hover {
  background: rgba(25, 118, 210, 0.04) !important;
}
.calendar-checklist .Mui-selected,
.calendar-checklist .MuiPickersDay-today {
  background: transparent !important;
  border-color: transparent !important;
}
.calendar-checklist .Mui-selected .Mui-selected {
  background: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: #fff !important;
}
.calendar-checklist .Mui-selected .Mui-selected:hover {
  background: var(--primaryColor) !important;
  filter: brightness(90%);
}
.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100% !important;
}

.team-confirmation-modal {
  z-index: 300 !important;
}
body:has(.team-confirmation-modal) .modal-backdrop.show {
  z-index: 299 !important;
}

/* IDF-6188, 6187 fix */
body:has(.search-dropdown-backdrop) .hide-on-scroll,
body:has(.modal-right.modal-right-show) .hide-on-scroll,
body:has(.modal-right.modal-right-show) .index-0-on-scroll {
  z-index: revert !important;
}

.h-200 {
  height: 200px !important;
}

.w-200 {
  width: 200px !important;
}

.max-w-120 {
  width: 120px !important;
}

.max-h-120 {
  max-height: 120px;
}

.max-w-80 {
  width: 80px !important;
}

.max-h-80 {
  max-height: 80px;
}

.filled-circle {
  width: 12px;
  height: 12px;
  border-radius: 22px;
}

.fti-item.etpr.chart {
  left: 47.5%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1600px) {
  .fti-item.etpr.chart {
    left: 49% !important;
  }
}
.etpr-chart-align {
  position: relative;
  top: -16px;
}

.tm-generated-rpt .peo-widget.disabled,
#rptPdf .peo-widget.disabled {
  display: none;
}

.tm-generated-rpt .list-group-item:has(.peo-widget.disabled),
#rptPdf .list-group-item:has(.peo-widget.disabled) {
  display: none;
}
.pointer-events-none {
  pointer-events: none !important;
}

.risk-colors {
  cursor: pointer;
}

.risk-colors:hover {
  filter: brightness(90%);
}

.risk-red {
  background: var(--rpt-color-red);
  border: 1px solid var(--rpt-color-red);
}

.risk-green {
  background: var(--rpt-color-green);
  border: 1px solid var(--rpt-color-green);
}

.risk-yellow {
  background: var(--rpt-color-yellow);
  border: 1px solid var(--rpt-color-yellow);
}

.risk-selected {
  border: 2px solid var(--primaryColor) !important;
}

.pill-selected .text-black {
  color: #fff !important;
}

.widget-table {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.widget-row {
  display: flex;
  border-bottom: 1px solid var(--rpt-widget-border-color);
  width: 96%;
}

.widget-heading {
  font-weight: bold;
}

.widget-cell {
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: space-evenly;
}

.widget-row .widget-cell:first-child {
  width: 15%;
  min-width: 100px;
}

.rotate-180 {
  transform: rotate(180deg) translate(0, 25%);
}

.horizontal-scroll-container {
  display: flex;
  align-items: center;
  position: relative;
  max-width: calc(100% - 30px);
  overflow: hidden;
  margin: 0 auto;
}

.arrow-scroll {
  cursor: pointer;
  padding: 5px;
  background-color: var(--primaryColor);
  z-index: 10;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  &:not(.faded):hover {
    filter: brightness(90%);
  }
}

.left-arrow {
  margin-right: 10px;
}

.right-arrow {
  margin-left: 20px;
}

.faded {
  opacity: 0.3;
  pointer-events: none;
}

.arrow-scroll.faded {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #444;
}
.scrollable-content {
  display: flex;
  overflow-x: hidden;
  flex: 1;
}

.scroll-items {
  display: flex;
  transition: transform 0.3s ease;
}

.scroll-item {
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &:hover i {
    transform: scale(1.1);
  }
}

.pagination {
  transition: all 0.2s linear;
  .page-link:not(.disabled) {
    border-radius: 50% !important;
    padding: 5px;
    color: var(--icon-text-color) !important;
  }
  .page-link:not(.disabled):hover {
    background-color: #eee !important;
    color: #444 !important;
  }
}

.horizontal-scroll-tabs {
  max-width: 100%;
}
.horizontal-scroll-tabs-active {
  padding: 0 8px;
}

.horizontal-scroll-tabs .scrollable-content {
  padding-bottom: 3px !important;
}

.horizontal-scroll-tabs .arrow-scroll {
  padding: 0 !important;
}

.horizontal-scroll-tabs .nav {
  flex-wrap: nowrap !important;
}

#dropdown-sub-org + .dropdown-menu {
  padding-top: 5px;
  padding-bottom: 5px;
}

#dropdown-sub-org + .dropdown-menu .dropdown-item:hover {
  background: var(--primaryColor);
  color: #fff;
}

.text-hover-bold:hover {
  font-weight: 450;
}

#clientPortalContent:has(.py-6.my-6) {
  #clientPortalHeading {
    display: none !important;
  }
}

@keyframes animate-pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  animation: animate-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin-slow {
  animation: spin-slow 3s linear infinite;
}

.activities-btn .show .modal-history-activities.dropdown-menu.show {
  opacity: 1 !important;
  pointer-events: auto !important;
}
