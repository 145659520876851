.header.mobile {
  display: none;
}

.breadcrumb {
  margin-bottom: 0;
}
@media only screen and (min-width: 1301px) and (max-width: 1920px) {
}

@media only screen and (max-width: 1440px) {
  .navbar-vertical-content {
    height: calc(100% - 2.75rem);
    overflow-y: auto;
  }
  .portal-menu-item i {
    font-size: 1.3rem;
    padding: 0.15rem !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .header .avatar-mobile {
    position: relative;
  }
  .lbl-btn-wrp {
    flex-direction: column;
  }
  .lbl-col-left {
    margin-bottom: 5px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .lbl-col-left p {
    width: 100%;
  }
  .lbl-col-right {
    display: flex;
    justify-content: space-between;
  }
  .breadcrumb-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .breadcrumb-desktop {
    display: none;
  }
  .breadcrumb-mobile .breadcrumb {
    padding: 0;
  }
  .global-search-dropdown {
    width: 90%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .header .avatar-mobile {
    position: relative;
  }
  .prospects-right {
    margin-left: 0 !important;
  }
  .lbl-btn-wrp {
    flex-direction: column;
  }
  .lbl-col-left {
    margin-bottom: 5px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .lbl-col-left p {
    width: 100%;
  }
  .lbl-col-right {
    display: flex;
    justify-content: space-between;
  }
  .breadcrumb-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .breadcrumb-desktop {
    display: none;
  }
  .breadcrumb-mobile .breadcrumb {
    padding: 0;
  }
  .global-search-dropdown {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar.header.navbar-height {
    height: auto;
  }
  .header.desktop {
    display: none;
  }
  .header.mobile {
    display: block;
  }
  .header.mobile .global-search-dropdown {
    width: 100%;
    z-index: auto;
  }
  .header.mobile .avatar-mobile,
  .header .avatar-mobile {
    position: relative;
  }
  .button-pannel {
    flex-direction: column-reverse;
  }
  .navbar-fixed ~ .main {
    padding-top: 6rem;
  }
  .insights-bar {
    top: 5.8rem;
  }
  .profile-options {
    justify-content: space-between;
  }
  .profile-col-right {
    padding-left: 15px !important;
  }
  .header.mobile .breadcrumb {
    margin: 0 !important;
    padding: 0;
  }
  .navbar-vertical-aside .navbar-vertical-content {
    padding-top: 2rem !important;
  }
  .client-portal-layout.navbar-vertical-aside .navbar-vertical-content {
    padding-top: 3.6rem !important;
  }
  .page-header.contact-header .media-body,
  .page-header.contact-header .profile-options {
    width: 100% !important;
  }
  .parent-board-header {
    z-index: initial;
  }
  .stats ul {
    padding: 0;
  }
  .pagination {
    margin: 0;
  }
  .prospects-right {
    margin-left: 0 !important;
  }
  .breadcrumb-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .breadcrumb-desktop {
    display: none;
  }
  .global-search-dropdown {
    width: 100%;
  }
  .breadcrumb-mobile .breadcrumb {
    padding: 0;
  }
  .right-col.border-left {
    border-left: none !important;
    border-top: 0.0625rem solid #e7eaf3 !important;
  }
  .vertical-section-board {
    flex-direction: column;
  }
  .vertical-section-chk-list,
  .vertical-section {
    padding-right: 15px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px !important;
  }
  .custom-layout-content,
  .MuiPaper-root {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .navbar.header.navbar-height {
    height: auto;
  }
  .header.desktop {
    display: none;
  }
  .header.mobile {
    display: block;
  }
  .header.mobile .global-search-dropdown {
    width: 100%;
    z-index: auto;
  }
  .header.mobile .avatar-mobile {
    position: relative;
  }
  .button-pannel {
    flex-direction: column-reverse;
  }
  .navbar-fixed ~ .main {
    padding-top: 6rem;
  }
  .page-header {
    width: 100% !important;
    display: flex;
    margin: 10px 0 0 0 !important;
    padding-bottom: 0 !important;
  }
  .home-card-item-new {
    max-width: 100% !important;
    width: 100%;
  }
  .insights-bar {
    top: 5.8rem;
  }
  .page-header.organization-header {
    display: inline-block;
  }
  .profile-options {
    justify-content: space-between;
  }
  .btn-add-activity {
    flex-direction: column;
    gap: 15px;
  }
  .profile-col-right {
    padding-left: 15px !important;
  }
  .modal-report-tabs {
    border-radius: 0;
  }
  .modal-report-tabs .nav-link {
    width: 100%;
  }
  .modal-right {
    min-width: 100% !important;
  }
  .col-form-label {
    text-align: left !important;
    padding-left: 0;
  }
  .header.mobile .breadcrumb {
    margin: 0 !important;
    padding: 0;
  }
  .modal-report-tabs {
    width: 100% !important;
    margin: 0 !important;
  }
  .report-controls {
    position: static !important;
    width: 100%;
    margin: 10px 0;
  }
  .report-controls .btn {
    width: 100%;
  }
  .navbar-vertical-aside .navbar-vertical-content {
    padding-top: 2rem !important;
  }
  .pipline-options-btn {
    flex-direction: column;
    gap: 10px 0;
  }
  .pipline-options-btn .hover-actions .dropdown,
  .pipline-options-btn .hover-actions .dropdown .dropdown-toggle {
    width: 100%;
  }
  .page-header.contact-header {
    flex-direction: column;
  }
  .page-header.contact-header .media-body,
  .page-header.contact-header .profile-options {
    width: 100% !important;
  }
  .btn-filter {
    width: 100%;
    padding: 0 15px !important;
  }
  .data-filter {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .filter-right {
    justify-content: space-between;
    width: 100%;
  }
  .filter-right .section-owners-header,
  .filter-right .mx-3 {
    margin: 0 !important;
  }
  .datatableCounterInfo {
    width: 100%;
    margin: 0 !important;
  }
  .datatableCounterInfo > div {
    width: 100%;
    justify-content: space-between;
  }
  .data-filter .dropdown,
  .data-filter .btn.dropdown-toggle {
    width: 100%;
  }
  .data-filter .dropdown {
    margin: 10px 0;
  }
  .data-filter .filter-right .dropdown,
  .data-filter .filter-right .btn.dropdown-toggle {
    width: auto;
  }
  .parent-board-header {
    z-index: initial;
  }
  .all-activities .button-outer-right {
    flex-direction: column;
  }
  .stats {
    max-width: none;
  }
  .stats ul {
    max-width: none;
    width: 100%;
  }
  .stats ul {
    padding: 10px;
    justify-content: center;
  }
  .pagination {
    justify-content: center;
    margin: 0;
  }
  .prospects-right {
    margin-left: 0 !important;
  }
  .btn-review-assignments {
    flex-direction: column;
    gap: 10px;
  }
  .ai-advisor {
    flex-direction: column;
  }
  .ai-advisor a {
    width: 100%;
  }
  .breadcrumb-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .breadcrumb-desktop {
    display: none;
  }
  .breadcrumb-mobile .breadcrumb {
    padding: 0;
  }
  .content .container {
    padding: 0;
  }
  .right-col.border-left {
    border-left: none !important;
    border-top: 0.0625rem solid #e7eaf3 !important;
  }
  .vertical-section-board {
    flex-direction: column;
  }
  .vertical-section-chk-list,
  .vertical-section {
    padding-right: 15px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px !important;
  }
  .custom-layout-content,
  .MuiPaper-root {
    width: 100% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .page-header .media {
    flex-direction: column;
  }
  .page-header .media .avatar-uploader {
    margin-bottom: 10px;
  }
  .page-header .media .avatar-uploader,
  .page-header .media .avatar-uploader .avatar-circle,
  .page-header .media .avatar-uploader .avatar-circle .avatar-initials {
    border-radius: 0;
    width: 100%;
  }
  .contact-header .client-share div,
  .contact-header .client-share .btn-icon {
    width: 100%;
    border-radius: 0 !important;
  }
}
