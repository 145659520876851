.main-bar {
  position: fixed;
  z-index: 100;
  /* width: calc(100% - 20rem);
  margin-left: 15rem !important; */
  padding-top: 11px;
  left: 50%;
  transform: translateX(-26%);
}

.global-search {
  position: relative;
  border-radius: 50px;
  border: 1px solid #d6dbeb;
  z-index: 98;
  background-color: #fff;
}
.global-search-dropdown.expand-width {
  width: 75% !important;
  transition: width 0.4s ease-in-out;
}
.search-fixed,
.search-fixed .input-group {
  width: 100%;
}
.search-fixed .input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}
.global-search:hover {
  background-color: #f6f7fa;
}

.modal-backdrop {
  z-index: 100;
}

.search-modal .modal-dialog {
  margin-top: 60px;
  max-width: 720px;
}

.search-options {
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-options .list-group-item.active {
  background-color: #e7ebed;
  color: #3c3f41;
  border: unset;
  margin-top: 0;
  font-weight: bold !important;
}

.search-options .list-group-item {
  color: #3c3f41;
}

.search-options .list-group-item:hover > span.material-symbols-rounded {
  color: #3c3f41;
  font-weight: bold !important;
}

.search-input:focus {
  box-shadow: unset !important;
}

input.search-input,
textarea.search-input {
  background-color: transparent !important;
}

#datatableSearch {
  max-width: 100%;
}
.search-close {
  color: #000;
}
.zIndex {
  z-index: 222 !important;
}
.search-dropdown-menu {
  position: absolute;
  inset: 0 auto auto 0;
  margin: 0;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 45px) !important;
  width: 100%;
  box-shadow: none !important;
}

.global-search-dropdown {
  z-index: 100;
}
.search-dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 27, 81, 0.25);
}
.global-search-dropdown {
  transition: width 0.4s ease-in-out;
  width: 40%;
}
.global-search-dropdown.expand-width {
  box-shadow: 0px 0px 10px 1px #22222256;
  border-radius: 45px;
  background-color: white;
}
.global-search-item {
  padding: 12px 16px !important;
  color: #3c3f41 !important;
  font-size: 13px;
  border: none !important;
  border-bottom: 1px solid rgba(189, 197, 209, 0.3) !important;
}

.global-search-item .main-icon {
  margin-top: 2px;
}

.item-text-secondary {
  font-size: 12px;
  font-weight: 500;
  color: #76797b;
}
.global-search-overlay {
  background: #000000cf;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  transition: all 1s ease-in-out;
  top: 0;
  z-index: 12;
}
